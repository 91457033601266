import {
  Modal,
  Button as AntButton,
  Form,
  Input,
  Select
} from 'antd'

import { useState } from 'react'
import Button from 'src/components/Button'
import { useAdminEvents } from 'src/hooks/event'
import styled from 'styled-components'
import * as c from '../../constants'

const ButtonContainer = styled.div`
  padding: 0.5em;
`
const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
`
const TitleContainer = styled.div`
  padding: 1em 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FF631B;
  & h2 {
    color: #fff;
  }
`
const FormContainer = styled.div`
  padding: 2em;
  & .ant-row.ant-form-item {
    flex: 1;
    justify-content: center;
    margin-bottom: 1em;
  }
  & .ant-col-14 {
    max-width: 100%;
  }
  & .ant-col-4 {
    padding-left: 8px;
    max-width: 100%;
    flex: 1;
  }
  & .ant-picker {
    width: 100%;
  }

  & .ant-divider{
    margin: 1em;
  }

  & .ant-form-large .ant-form-item-label > label {
    height: 25px;
  }
`

const EVENT_TYPE_OPTIONS = [
  {
    label: 'Webhook',
    value: 'webhooks'
  },
  {
    label: 'MQTT',
    value: 'mqtt'
  }
]

const AdminAddEventModal = ({
  edit = false,
  record = {},
  shouldUpdate
}) => {
  const { addEvent, updateEvent } = useAdminEvents({ fetch: false, id: record.id })
  const [visible, setVisible] = useState(false)
  const [form] = Form.useForm()

  const showModal = () => {
    setVisible(true)
  }

  const handleCancel = e => {
    form.resetFields()
    setVisible(false)
  }

  const handleSubmit = async item => {
    if (edit) {
      const editPayload = { id: record.id, ...item }
      await updateEvent(editPayload)
    } else {
      await addEvent(item)
    }

    form.resetFields()
    setVisible(false)
    shouldUpdate?.()
  }

  return (
    <>
      {edit
        ? (
          <AntButton type='link' onClick={showModal}>
            Edit
          </AntButton>)
        : (
          <Button type='primary' onClick={showModal}>
            Add event
          </Button>)}
      <Modal
        closable={false}
        visible={visible}
        destroyOnClose
        onCancel={handleCancel}
        bodyStyle={{ padding: '0' }}
        footer={[
          <FooterContainer key='buttons'>
            <ButtonContainer>
              <Button fill outline key='cancel' onClick={handleCancel}>
              Cancel
              </Button>
            </ButtonContainer>
            <ButtonContainer>
              <Button key='submit' type='primary' htmlType='submit' form='addEvent'>
                {edit ? 'Update event' : 'Add event'}
              </Button>
            </ButtonContainer>
          </FooterContainer>
        ]}
      >
        <div>
          <TitleContainer>
            <h2>{edit ? 'Edit event' : 'Add event'}</h2>
          </TitleContainer>
          <FormContainer>
            <Form
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 14 }}
              layout='vertical'
              size='large'
              id='addEvent'
              onFinish={handleSubmit}
              colon={false}
              form={form}
              initialValues={record}
            >
              {() => (
                <>
                  <Form.Item
                    label='Label'
                    name='label'
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: 'Please add a label'
                      }
                    ]}
                  >
                    <Input
                      placeholder='Label'
                      allowClear
                    />
                  </Form.Item>
                  <Form.Item
                    label='Event'
                    name='event'
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: 'Please add an event'
                      }
                    ]}
                  >
                    <Input
                      placeholder='Event'
                      disabled={edit}
                      allowClear
                    />
                  </Form.Item>
                  <Form.Item
                    label='Output event'
                    name='outputEvent'
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: 'Please add an output event'
                      }
                    ]}
                  >
                    <Input
                      placeholder='Output event'
                      disabled={edit}
                      allowClear
                    />
                  </Form.Item>
                  <Form.Item
                    label='Type'
                    name='type'
                    hasFeedback
                    initialValue={EVENT_TYPE_OPTIONS[0].value}
                    rules={[
                      {
                        required: true,
                        message: 'Please add a type'
                      }
                    ]}
                  >
                    <Select
                      placeholder='Type'
                      disabled={edit}
                      options={EVENT_TYPE_OPTIONS}
                    />
                  </Form.Item>
                </>
              )}
            </Form>
          </FormContainer>
        </div>
      </Modal>
    </>
  )
}

export default AdminAddEventModal
