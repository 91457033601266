import { useState, useEffect } from 'react'
import AdminList from 'src/components/AdminList'
import { Tag, Space, Tooltip, Popconfirm, Badge } from 'antd'
import { navigate } from '@redwoodjs/router'
import { QuestionCircleOutlined } from '@ant-design/icons'
import AdminAddSensorModal from 'src/components/AdminAddSensorModal'

import { useAdminAllSensors, useSensors, useSensorTypes, useAdminAllUsers, useAdminAllSpaces, useTenant } from 'src/hooks'
import * as c from 'src/constants'

const AdminSensor = ({ typeName }) => {
  const { getRoute, isTenantScope } = useTenant()
  const { adminDeleteSensor } = useSensors({ fetch: false })
  const { refresh, sensors, loading, paginationState, searchState } = useAdminAllSensors({
    includeSettings: true
  })
  const { sensorTypes } = useSensorTypes()
  const { users } = useAdminAllUsers()
  const { spaces } = useAdminAllSpaces()

  const handleClick = (record) => {
    const { id } = record
    navigate(getRoute('adminSensor', { id }))
  }

  const shouldUpdate = () => {
    refresh()
  }

  const handleDelete = (record) => {
    adminDeleteSensor(record)
      .then(res =>
        refresh()
      )
  }

  const columns = [
    {
      title: '',
      dataIndex: 'online',
      key: 'online',
      render: online => online
        ? <Badge count='online' style={{ backgroundColor: '#52c41a' }} />
        : <Badge count='offline' />
    },
    {
      title: 'Name',
      dataIndex: 'formattedName',
      key: 'formattedName',
      render: (text, record) => <a>{text}</a>
    },
    {
      title: 'Connection Type',
      dataIndex: 'connectionType',
      key: 'connectionType'
    },
    {
      title: 'Space',
      dataIndex: 'space',
      render: space => {
        if (!space) return ''
        const color = space.type === c.TYPE_ROOM
          ? 'geekblue'
          : 'red'
        const title = space.type !== c.TYPE_ROOM &&
          'You should not put sensors other than in rooms.'
        return (
          <Tooltip title={title}>
            <Tag color={color}>{space.name} · {space.id}</Tag>
          </Tooltip>
        )
      }
    },
    {
      title: 'Owner',
      key: 'owner',
      dataIndex: 'owner',
      responsive: ['md'],
      render: owner => {
        if (owner) {
          const color = owner.length > 5 ? 'geekblue' : 'green'
          if (owner === 'linus') {
            return (
              <Tag color='red' key={owner}>
             NOOB
              </Tag>
            )
          }
          return (
            <Tag color={color} key={owner}>
              {owner.toUpperCase()}
            </Tag>
          )
        }
      }

    },
    {
      title: 'Device ID',
      dataIndex: 'deviceId',
      key: 'deviceId',
      responsive: ['md']
    },
    {
      title: 'Notifications',
      dataIndex: 'notifications',
      key: 'notifications',
      responsive: ['md'],
      render: notifications => {
        return notifications === 1 ? <i className='fas fa-check' /> : ''
      },
      filters: [
        {
          text: 'Notifications',
          value: 1
        },
        {
          text: 'No Notifications',
          value: 0
        }
      ],
      onFilter: (value, record) => record.notifications === value
    },
    {
      title: 'Notification Email',
      dataIndex: 'notificationEmail',
      key: 'notificationEmail',
      responsive: ['md'],
      render: notificationEmail => {
        return notificationEmail
      }
    },
    {
      title: 'Retired',
      dataIndex: 'retired',
      key: 'retired',
      responsive: ['md'],
      render: retired => {
        return retired === 1 ? <i className='fas fa-check' /> : ''
      },
      filters: [
        {
          text: 'Retired',
          value: 1
        },
        {
          text: 'Not Retired',
          value: 0
        }
      ],
      onFilter: (value, record) => record.retired === value
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size='middle'>
          <div onClick={(e) => e.stopPropagation()}>
            <Popconfirm
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              title='Are you sure delete this sensor?'
              onConfirm={() => handleDelete(record)}
              okText='Yes, delete'
              cancelText='No'
            >
              <a href='#'>Delete</a>
            </Popconfirm>
          </div>
          {!isTenantScope() && (
            <AdminAddSensorModal
              shouldUpdate={shouldUpdate}
              tableData={sensors}
              record={record}
              edit
              sensorTypes={sensorTypes}
              users={users}
              spaces={spaces}
            />
          )}

          <a><i className='fas fa-caret-right' /></a>
        </Space>
      )
    }
  ]

  return (
    <AdminList
      handleClick={(e) => handleClick(e)}
      shouldUpdate={shouldUpdate}
      typeName={typeName}
      data={sensors}
      columns={columns}
      loading={loading}
      sensorTypes={sensorTypes}
      users={users}
      spaces={spaces}
      searchProps={{
        onChange: e => searchState.setValue(e.target.value),
        onSearch: () => searchState.cancelTimeout(),
        onPressEnter: () => searchState.cancelTimeout(),
        value: searchState.value
      }}
      tableProps={{
        hidePaginationNumbers: true,
        rowKey: sensor => sensor.id,
        pagination: {
          ...paginationState.antdPaginationConfig,
          onChange: (page) => {
            paginationState.goToPrevOrNextPage(page)
          },
          showSizeChanger: false
        }
      }}
    />
  )
}

export default AdminSensor
