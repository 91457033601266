import { useState, useEffect } from 'react'
import { navigate } from '@redwoodjs/router'
import AppLayout from 'src/layouts/AppLayout'
import AppHeader from 'src/components/AppHeader'
import AppContent from 'src/components/AppContent'
import { BackButton } from 'src/components/Button'
import AppTitle from 'src/components/AppTitle'
import Spin from 'src/components/Spin'
import AdminSensorStats from 'src/components/AdminSensorStats'
import * as c from 'src/constants'
import { Badge, Space, Tabs, Tag } from 'antd'

import { useAdminAllSpaces, useAdminSensor, useAuth, useTenant } from 'src/hooks'
import styled from 'styled-components'
import AdminAssignSpaceSensor from '../../components/AdminAssignSpaceSensor'
import AdminSensorAccess from 'src/components/SensorTenantAccess'
import CommandCenter from 'src/components/CommandCenter'
const { TabPane } = Tabs

const Title = styled(AppTitle)`
  display: inline-flex;
  align-items: center;
  gap: 1rem;
`

const ConnectionTag = styled(Tag)`
  display: inline-block;
  font-size: 0.5em;
  padding: 0.4em 0.5em;
  line-height: 0.5em;
  text-transform: lowercase;
`

const SideBar = styled.div`
  flex: 1;
  background-color: #FFFFFF;
  box-shadow: 5px 0px 10px rgba(0,0,0,0.15);
  padding: 1rem;
`

const Content = styled.div`
  flex: 2;
  padding: 1rem;
  display: flex;
  height: 100%;
  flex-direction: column;
`

const FullSpace = styled(Space)`
  width: 100%;
`

const AdminSensorPage = ({ id }) => {
  const { getRoute, isTenantScope } = useTenant()
  const { hasRole } = useAuth()
  const [buildings, setBuildings] = useState([])
  const [floors, setFloors] = useState([])
  const [rooms, setRooms] = useState([])
  const { spaces } = useAdminAllSpaces()
  const { sensor, loading, refresh } = useAdminSensor(id)
  const sortSpaces = (spaces) => {
    const GroupType = 'type'
    const GroupedSpaces = spaces.reduce((acc, item) => {
      if (!acc[item[GroupType]]) {
        acc[item[GroupType]] = []
      }

      acc[item[GroupType]].push(item)
      return acc
    }, {})
    setBuildings(GroupedSpaces[c.TYPE_BUILDING])
    setFloors(GroupedSpaces[c.TYPE_FLOOR])
    setRooms(GroupedSpaces[c.TYPE_ROOM])
  }

  useEffect(() => {
    sortSpaces(spaces)
  }, [spaces])

  return (
    <AppLayout>
      <AppHeader noMargin>
        <BackButton onClick={(e) => navigate(getRoute('adminContent', { type: 'sensors' }))} />
        <Title noMargin>
          {sensor.online
            ? <Badge count='online' style={{ backgroundColor: '#52c41a' }} />
            : <Badge count='offline' />
          }
          {sensor.name}
          {sensor.connectionType && (
            <ConnectionTag color='blue'>
              {sensor.connectionType}
            </ConnectionTag>
          )}
        </Title>
      </AppHeader>
      <AppContent noPadding>
        {loading || !sensor
          ? <Spin />
          : (
            <>
              <SideBar>
                <AdminSensorStats sensor={sensor} />
              </SideBar>
              <Content>
                <Tabs defaultActiveKey='1'>
                  <TabPane tab='Access' key='1'>
                    <FullSpace size='middle' direction='vertical'>
                      <AdminAssignSpaceSensor
                        sensor={sensor}
                        buildings={buildings}
                        floors={floors}
                        rooms={rooms}
                      />
                      <AdminSensorAccess
                        refresh={refresh}
                        sensor={sensor}
                      />
                    </FullSpace>
                  </TabPane>
                  <TabPane tab='Command center' key='2'>
                    <FullSpace size='middle' direction='vertical'>
                      <CommandCenter sensor={sensor} />
                    </FullSpace>
                  </TabPane>
                </Tabs>

              </Content>
            </>
          )}
      </AppContent>
    </AppLayout>
  )
}

export default AdminSensorPage
