import {
  Modal,
  Form,
  Input,
  Empty,
  Card
} from 'antd'
import { useEffect, useState } from 'react'
import Button from 'src/components/Button'
import ConfigurePlugin from 'src/components/ConfigurePlugin'
import ConfigureSwitch from 'src/components/ConfigureSwitch'
import ConfiguredPluginModal from 'src/components/ConfiguredPluginModal'
import { usePlugins } from 'src/hooks'
import * as c from 'src/constants'

import styled from 'styled-components'

const ButtonContainer = styled.div`
  padding: 0.5em;
`
const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
`
const TitleContainer = styled.div`
  padding: 1em 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${props => props.theme.primaryColor || '#FF631B'};
  & h2 {
    color: #fff;
  }
`
const FormContainer = styled.div`
  padding: 2em;
  & .ant-row.ant-form-item {
    justify-content: center;
  }
  & .ant-col-14 {
    max-width: 100%;
  }
  & .ant-col-4 {
    max-width: 100%;
  }
`

const PortsWrap = styled.div`
  margin: 2em 0;
  display: grid;
  grid-template-columns: repeat(3, minmax(30%, 1fr));
  grid-gap: 1em;
  @media (max-width: ${c.SMALL_SCREEN}){
    grid-template-columns: repeat(1, minmax(90%, 1fr));
  }
`

const Center = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`

const UniFont = styled.span`
  font-weight: normal;
  color: ${props => props.color || 'black'};
  font-size: 20px;
`

const NumberFont = styled.span`
  font-weight: 600;
  color: ${props => props.color || 'black'};
  font-size: 36px;

`

const InfoFont = styled.p`
  font-size: 0.8em;
  font-style: italic;
  margin: 0;
`

const EmptyWrap = styled(Empty)`
  padding: 2em 0;
`

const SwitchModal = (props) => {
  const {
    children,
    sensor = {},
    shouldUpdate,
    plugin,
    settings
  } = props

  const { getPlugins } = usePlugins({ fetch: false })

  const [formActive, setFormActive] = useState(1)
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [plugins, setPlugins] = useState([])
  const [pluginSelected, setPluginSelected] = useState()
  const [pluginArray, setPluginArray] = useState()
  const [metaData, setMetaData] = useState()
  const [selectedPort, setSelectedPort] = useState()
  const [form] = Form.useForm()

  const showModal = () => {
    setVisible(true)
  }

  const handleCancel = e => {
    setVisible(false)
    setFormActive(1)
    setPluginSelected(undefined)
    setSelectedPort(undefined)
  }

  const handleSubmit = item => {
    shouldUpdate()
  }

  const getSensorPlugins = (plugins) => {
    const PluginArray = plugins.map(item => {
      const SensorPlugin = item.SensorPlugin || {}
      return SensorPlugin.port
    })
    setPluginArray(PluginArray)
  }

  useEffect(() => {
    setLoading(true)
    const { sensorType = {}, plugins = [] } = sensor
    const { metaData: metaText } = sensorType
    if (metaText) {
      setMetaData(JSON.parse(metaText))
    }
    getSensorPlugins(plugins)
    getPlugins()
      .then((res) => {
        setPlugins(res)
        setLoading(false)
      }).catch(() => {
        setLoading(false)
      })
  }, [sensor])

  useEffect(() => {
    if (plugin) {
      setFormActive(2)
    }
  }, [plugin, formActive])

  return (
    <>
      <div onClick={showModal}>{children ||
        <Button type='primary'>
          Configure
        </Button>}
      </div>
      <Modal
        onCancel={handleCancel}
        closable={false}
        visible={visible}
        destroyOnClose
        bodyStyle={{ padding: '0' }}
        footer={[
          <FooterContainer key='buttons'>
            <ButtonContainer>
              <Button fill outline key='cancel' onClick={handleCancel}>
              Cancel
              </Button>
            </ButtonContainer>
            {(formActive !== 4) &&
              <ButtonContainer>
                <Button key='submit' type='primary' htmlType='submit' form='addPlugin'>
                  {'Save'}
                </Button>
              </ButtonContainer>}
          </FooterContainer>
        ]}
      >
        <TitleContainer>
          <h2>Configure</h2>
        </TitleContainer>
        <FormContainer>

          {formActive === 1 &&
            <>
              <Form
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 14 }}
                layout='vertical'
                size='large'
                id='addPlugin'
                onFinish={handleSubmit}
                colon={false}
                form={form}
                initialValues={{
                  ...sensor
                }}
              >
                <Form.Item
                  label='NAME'
                  name='name'
                >
                  <Input
                    placeholder='Switch name'
                    allowClear
                  />
                </Form.Item>
                <Button style={{ width: '100%' }} onClick={() => setFormActive(2)}>Link Machine</Button>
                {metaData && metaData.ports

                  ? (
                    <PortsWrap>
                      {metaData.ports.map(item => {
                        const { key, displayName } = item
                        const occ = pluginArray.reduce((n, x) => n + (x === key), 0)
                        let buttonValue = 2
                        if (occ > 0) {
                          buttonValue = 4
                        }
                        return (
                          <Card
                            onClick={() => {
                              setSelectedPort(key)
                              setFormActive(buttonValue)
                            }} key={key} hoverable
                          >
                            <Center>
                              <UniFont>{displayName}</UniFont>
                            </Center>
                            <Center>
                              <NumberFont>{occ}</NumberFont>
                            </Center>
                            <Center>
                              <InfoFont>Machines linked</InfoFont>
                            </Center>
                          </Card>
                        )
                      })}
                    </PortsWrap>
                  )
                  : (
                    <EmptyWrap
                      description={
                        <span>No port configured to sensor</span>
                      }
                    />)}
              </Form>
            </>}
          {(formActive === 2) &&
            <ConfigurePlugin
              plugin={plugin}
              settings={settings}
              sensor={sensor}
              plugins={plugins}
              formActive={formActive}
              setFormActive={setFormActive}
              setLoading={setLoading}
              setPluginSelected={setPluginSelected}
              pluginSelected={pluginSelected}
              handleCancel={handleCancel}
              shouldUpdate={shouldUpdate}
              metaData={metaData}
              setSelectedPort={setSelectedPort}
              selectedPort={selectedPort}
            />}
          {formActive === 3 &&
            <ConfigureSwitch
              setFormActive={setFormActive}
              setLoading={setLoading}
              sensor={sensor}
            />}
          {formActive === 4 &&
            <ConfiguredPluginModal
              setFormActive={setFormActive}
              setLoading={setLoading}
              setSelectedPort={setSelectedPort}
              selectedPort={selectedPort}
              sensor={sensor}
            />}
        </FormContainer>
      </Modal>
    </>
  )
}

export default SwitchModal
