import * as c from 'src/constants'

const asArray = (array) => Array.isArray(array) ? array : []

export const roomStyles = {
  [c.ROOM_KITCHEN]: {
    displayName: 'Kitchen',
    primaryColor: '#0fd6ab',
    primaryTextColor: 'black',
    iconClassName: 'fas fa-utensils'
  },
  [c.ROOM_BEDROOM]: {
    displayName: 'Bedroom',
    primaryColor: '#f59487',
    primaryTextColor: 'black',
    iconClassName: 'fas fa-couch'
  },
  [c.ROOM_BATHROOM]: {
    displayName: 'Bathroom',
    primaryColor: '#c772ff',
    primaryTextColor: 'white',
    iconClassName: 'fas fa-sink'
  },
  [c.ROOM_TOILET]: {
    displayName: 'Toilet',
    primaryColor: '#f9da3c',
    primaryTextColor: 'black',
    iconClassName: 'fas fa-toilet'
  },
  [c.ROOM_HALL]: {
    displayName: 'Hall',
    primaryColor: '#f9da3c',
    primaryTextColor: 'black',
    iconClassName: 'fas fa-chalkboard-teacher'
  },
  [c.ROOM_OFFICE]: {
    displayName: 'Office',
    primaryColor: 'gray',
    primaryTextColor: 'white',
    iconClassName: 'fas fa-laptop-house'
  },
  default: {
    primaryColor: '#FF631B',
    primaryTextColor: 'white'
  }
}

export const getRoomStyle = (category) => {
  const style = roomStyles[category]
  return style || roomStyles.default
}

export const groupSpacesBy = (spaces, key) =>
  asArray(spaces).reduce((acc, space) => ({
    ...acc,
    ...space?.[key] ? {
      [space[key]]: acc[space[key]]
        ? [...acc[space[key]], space]
        : [space]
    } : {}
  }), {})

export const groupSpacesByParent = (spaces) =>
  groupSpacesBy(spaces, 'parentSpaceId')

export const groupSpacesByType = (spaces) =>
  groupSpacesBy(spaces, 'type')

export const getParents = (spaces = [], spaceId, acc = []) => {
  const currentSpace = spaces.find((space) => space.id === spaceId)
  const parent = spaces.find((space) => currentSpace?.parentSpaceId === space.id)
  if (!parent) return acc
  return [
    ...(parent
      ? getParents(spaces, parent.id, [...acc, parent])
      : [...acc, parent])
  ]
}

export const _getChildren = (space = {}, spaces, acc = []) => {
  const children = spaces.filter((s) => s.parentSpaceId === space.id)
  if (children.length === 0) return acc
  const rec = [
    ...acc,
    ...children.map(
      (child) => _getChildren(child, spaces, [child])
    )
  ]
  return rec.reduce((arr, cur) => Array.isArray(cur)
    ? [...arr, ...cur]
    : [...arr, cur], []) // flatten array
}

export const getChildren = (spaces, spaceId) => {
  const space = spaces.find((s) => s.id === spaceId)
  const children = _getChildren(space, spaces)

  return children.length >= 1 ? children : [space]
}

export const getScores = (sensors) => {
  const scores = sensors
    .map(({ latestData } = {}) => latestData?.scores)
    .filter((cur) => cur != null)
  const sums = scores.reduce((acc, cur) => {
    if (!cur) return acc
    const keys = Object.keys(cur)
    if (keys.length === 0) return acc
    return {
      ...acc,
      ...keys.reduce((accKey, key) => ({
        ...accKey,
        [key]: acc[key]
          ? acc[key] + cur[key] // already exists value
          : cur[key] || 0 // no previous value
      }), {})
    }
  }, {})
  const avg = Object.entries(sums)
    .reduce((acc, [key, value]) => ({
      ...acc,
      [key]: value / scores.length
    }), {})
  return avg
}

export const getIssues = (sensors) => {
  const issues = sensors.map(({ issues }) => issues)
  return issues.reduce((acc, cur) => [...acc, ...(cur || [])], [])
}

export const getMeasurements = (sensors) => {
  if (!sensors) return
  const scores = sensors
    .map(({ latestData } = {}) => latestData?.data)
    .filter((cur) => cur != null)
  const sums = scores.reduce((acc, cur) => {
    if (!cur) return acc
    const keys = Object.keys(cur)
    if (keys.length === 0) return acc
    return {
      ...acc,
      ...keys.reduce((accKey, key) => ({
        ...accKey,
        [key]: acc[key]
          ? acc[key] + cur[key] // already exists value
          : cur[key] || 0 // no previous value
      }), {})
    }
  }, {})
  const avg = Object.entries(sums)
    .reduce((acc, [key, value]) => ({
      ...acc,
      [key]: value / scores.length
    }), {})
  return avg
}

export const getSpaceRelations = (spaces, spaceId) => {
  spaces = asArray(spaces)
  let parents
  let children
  let rooms
  let floors
  let sensors
  let buildings = []
  let maxAccessLevel
  if (spaceId) {
    parents = getParents(spaces, spaceId)
    children = getChildren(spaces, spaceId)
    rooms = children.filter((space) => space?.id !== spaceId && space?.type === c.TYPE_ROOM)
    floors = children.filter((space) => space?.id !== spaceId && space?.type === c.TYPE_FLOOR)
    sensors = children
      .map(({ sensors = [] } = {}) => sensors)
      .reduce((arr, sensors) => [...arr, ...sensors], []) // flatten arr
    maxAccessLevel = children.reduce((acc, cur) => cur?.accessLevel > acc
      ? cur?.accessLevel
      : acc,
    0)
  } else {
    buildings = spaces.filter((space) => space.type === c.TYPE_BUILDING)
    rooms = spaces.filter((space) => space.type === c.TYPE_ROOM)
    floors = spaces.filter((space) => space.type === c.TYPE_FLOOR)
    sensors = spaces.map(({ sensors }) => sensors || [])
      .reduce((arr, sensors) => [...arr, ...sensors], []) // flatten arr
    maxAccessLevel = spaces.reduce((acc, cur) => cur.accessLevel > acc
      ? cur.accessLevel
      : acc,
    0)
  }
  const scores = getScores(sensors)
  const issues = getIssues(sensors)
  const measurements = getMeasurements(sensors)
  return {
    rooms,
    floors,
    sensors,
    parents,
    buildings,
    children,
    measurements,
    scores,
    maxAccessLevel,
    issues
  }
}

export const getSpaceNameFromType = type => {
  switch (type) {
    case 1:
      return 'Building'
    case 2:
      return 'Floor'
    case 3:
      return 'Room'
    default:
      return type
  }
}

export const getCoordinatesObj = (record, key = 'coordinates') => ({
  ...record ?? {},
  [key]: {
    type: record?.[key]?.type,
    coordinates: record?.[key]?.coordinates ?? [null, null]
  }
})
