import { navigate } from '@redwoodjs/router'
import AppLayout from 'src/layouts/AppLayout'
import AppHeader from 'src/components/AppHeader'
import AppContent from 'src/components/AppContent'
import Button, { BackButton } from 'src/components/Button'
import AppTitle from 'src/components/AppTitle'
import Spin from 'src/components/Spin'
import AdminUserAccess from 'src/components/AdminUserAccess'
import AdminUserStats from 'src/components/AdminUserStats'
import { Popconfirm, Tabs } from 'antd'

import { useAdminUser, useAuth, useTenant } from 'src/hooks'
import styled from 'styled-components'
import { ACCESS_WRITE } from 'src/constants'
const { TabPane } = Tabs

const SideBar = styled.div`
  flex: 1;
  background-color: #FFFFFF;
  box-shadow: 5px 0px 10px rgba(0,0,0,0.15);
  padding: 1rem;
`

const Content = styled.div`
  flex: 2;
  padding: 1rem;
  display: flex;
  height: 100%;
  flex-direction: column;
`

const AdminUserPage = ({ id }) => {
  const { getRoute, isTenantScope, tenantId } = useTenant()
  const { hasRole } = useAuth()
  const { loading, user = {}, refresh, sendForgotPwdEmail } = useAdminUser(id)


  return (
    <AppLayout>
      <AppHeader noMargin>
        <BackButton onClick={(e) => navigate(getRoute('adminContent', { type: 'users' }))} />
        <AppTitle noMargin>{user.displayname}</AppTitle>
      </AppHeader>
      <AppContent noPadding>
        {loading || !user
          ? <Spin />
          : (
            <>
              <SideBar>
                {user && (
                  <>
                    <AdminUserStats user={user} />
                    {(
                      hasRole('superAdmin') ||
                      (isTenantScope() && hasRole(`tenant.${tenantId}.${ACCESS_WRITE}`))
                    ) && (
                      <Popconfirm
                        title={`Are you sure you want to send a reset password email to ${user.email}`}
                        onConfirm={sendForgotPwdEmail}
                        okText='Yes'
                        cancelText='Cancel'
                      >
                        <Button fill outline>Send reset password email</Button>
                      </Popconfirm>
                    )}
                  </>
                )}
              </SideBar>
              <Content>
                <Tabs defaultActiveKey='1'>
                  <TabPane tab='Access' key='1'>
                    <AdminUserAccess
                      user={user}
                      refresh={refresh}
                    />
                  </TabPane>
                </Tabs>
              </Content>
            </>
          )}
      </AppContent>
    </AppLayout>
  )
}

export default AdminUserPage
