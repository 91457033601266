import {
  Modal,
  Button as AntButton,
  Form,
  Input,
  Select,
  Spin,
  Alert,
  Radio
} from 'antd'

import { useEffect, useState } from 'react'
import Button from 'src/components/Button'
import LoadingScreen from 'src/components/LoadingScreen'
import { useSpaceUser, useAdminAllUsers } from 'src/hooks'

import styled from 'styled-components'
import * as c from '../../constants'

const { Option } = Select

const ButtonContainer = styled.div`
  padding: 0.5em;
`
const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
`
const TitleContainer = styled.div`
  padding: 1em 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FF631B;
  & h2 {
    color: #fff;
  }
`
const FormContainer = styled.div`
  padding: 2em;
  & .ant-row.ant-form-item {
    flex: 1;
    justify-content: center;
    margin-bottom: 1em;
  }
  & .ant-col-14 {
    max-width: 100%;
  }
  & .ant-col-4 {
    padding-left: 8px;
    max-width: 100%;
    flex: 1;
  }
  & .ant-picker {
    width: 100%;
  }

  & .ant-divider{
    margin: 1em;
  }

  & .ant-form-large .ant-form-item-label > label {
    height: 25px;
  }
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  & .ant-form-item-control{
    padding: 0 0.5em;
  }
  .ant-form-item-control-input-content{
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  @media (max-width: ${c.SMALL_SCREEN}){
    flex-direction: column;
  }
`

const RadioButton = styled(Radio.Button)`
  font-size: 22px;
  &.ant-radio-button-wrapper-checked{
    background: #FF631B;
    color: white;
  }
`

const spaceUserAccessModal = (props) => {
  const {
    spaceId,
    children,
    users: spaceUsers,
    edit = false,
    refresh
  } = props
  const { adminAddUserToSpace } = useSpaceUser()

  console.log('spaceUsers', spaceUsers)

  const { loading, users } = useAdminAllUsers()
  const [visible, setVisible] = useState(false)
  const [form] = Form.useForm()

  const showModal = () => {
    setVisible(true)
  }

  const handleCancel = e => {
    form.resetFields()
    setVisible(false)
  }

  const handleSubmit = obj => {
    const { userId, accessLevel } = obj
    const { key } = userId
    const payload = { userId: key, spaceId: spaceId, accessLevel: accessLevel }
    adminAddUserToSpace(payload)
      .then((res) => {
        form.resetFields()
        setVisible(false)
        refresh()
      }).catch(() => {})
  }

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <div onClick={showModal}>
        {children ||
        (
          <Button type='primary'>
        Add user
          </Button>
        )}
      </div>
      {visible &&
        <Modal
          closable={false}
          visible={visible}
          destroyOnClose
          onCancel={handleCancel}
          bodyStyle={{ padding: '0' }}
          footer={[
            <FooterContainer key='buttons'>
              <ButtonContainer>
                <Button fill outline key='cancel' onClick={handleCancel}>
              Cancel
                </Button>
              </ButtonContainer>
              <ButtonContainer>
                <Button key='submit' type='primary' htmlType='submit' form='addMachine'>
                  {edit ? 'Update user' : 'Add user'}
                </Button>
              </ButtonContainer>
            </FooterContainer>
          ]}
        >
          <div>
            <TitleContainer>
              <h2>{edit ? 'Edit user' : 'Add user'}</h2>
            </TitleContainer>
            <FormContainer>
              {loading
                ? <LoadingScreen />
                : (
                  <Form
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 14 }}
                    layout='vertical'
                    size='large'
                    id='addMachine'
                    onFinish={handleSubmit}
                    colon={false}
                    form={form}
                  >
                    <Form.Item
                      name='accessLevel'
                      label='Select accessLevel'
                      rules={[{ required: true, message: 'Please select an access level' }]}
                    >
                      <Radio.Group>
                        <RadioButton value={c.SPACE_USER}>User</RadioButton>
                        <RadioButton value={c.SPACE_INSTALLER}>Installer</RadioButton>
                        <RadioButton value={c.SPACE_OWNER}>Owner</RadioButton>
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item
                      label='Select user'
                      name='userId'
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: `Please select a user to add to space ${spaceId}`
                        }]}
                    >
                      {users
                        ? (
                          <Select
                            labelInValue
                            placeholder='Select users'
                            notFoundContent={loading ? <Spin size='small' /> : null}
                            style={{ width: '100%' }}
                            showSearch
                            optionFilterProp='children'
                            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                          >
                            {users.map(user => {
                              const status = Object.keys(spaceUsers).filter(key => { return spaceUsers[key].userId === user.id })
                              return (<Option disabled={!!status.length} key={user.id}>{user.username}</Option>)
                            })}
                          </Select>
                        )
                        : (
                          <Alert
                            message='Warning'
                            description='No users found'
                            type='error'
                            showIcon
                          />)}
                    </Form.Item>
                  </Form>)}
            </FormContainer>
          </div>
        </Modal>}
    </div>
  )
}

export default spaceUserAccessModal
