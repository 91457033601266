import {
  Modal,
  Button as AntButton,
  Form,
  Input,
  message,
  Switch,
  Checkbox
} from 'antd'
import { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import { useRef, useState } from 'react'
import styled from 'styled-components'
import { valid } from 'semver'

import Button from 'src/components/Button'
import { useAdminCommands, useSensorTypes } from 'src/hooks'
import { useAdminSensorSetting } from 'src/hooks/sensorSetting'
import * as c from 'src/constants'
import CodeEditor from 'src/components/CodeEditor/CodeEditor'

const METAOBJECT = { displayName: '', key: '' }

const ButtonContainer = styled.div`
  padding: 0.5em;
`
const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
`
const TitleContainer = styled.div`
  padding: 1em 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FF631B;
  & h2 {
    color: #fff;
  }
`
const FormContainer = styled.div`
  padding: 2em;
  & .ant-row.ant-form-item {
    flex: 1;
    justify-content: center;
    margin-bottom: 1em;
  }
  & .ant-col-14 {
    max-width: 100%;
  }
  & .ant-col-4 {
    padding-left: 8px;
    max-width: 100%;
    flex: 1;
  }
  & .ant-picker {
    width: 100%;
  }

  & .ant-divider{
    margin: 1em;
  }

  & .ant-form-large .ant-form-item-label > label {
    height: 25px;
  }
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  & .ant-form-item-control{
    padding: 0 0.5em;
  }
  @media (max-width: ${c.SMALL_SCREEN}){
    flex-direction: column;
  }
`

const Right = styled.div`
  display: flex;
  justify-content: flex-end;
  & button {
    min-width: 60px;
  }
`

const ColumnWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const CheckboxGroup = styled(Checkbox.Group)`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
`

const CheckAllRow = styled(Row)`
  .ant-row.ant-form-item{
    margin-bottom: 0;
  }

  .ant-form-item-control-input {
    min-height: 0;
    margin-bottom: 0.2rem;
  }

  .ant-col-14 {
    display: inline-flex;
    flex-direction: row;
  }
`

const CheckboxFormItem = styled(Form.Item)`
  margin-left: 0.5rem;

  &.ant-row.ant-form-item {
    display: block;
  }
`

const hasCustomNamePattern = patternObj => typeof patternObj?.like === 'string' && patternObj?.like !== '' &&
  typeof patternObj?.orderBySubstringIndexVal === 'string' && patternObj?.orderBySubstringIndexVal !== '' &&
  typeof patternObj?.prefix === 'string' && patternObj?.prefix !== '' &&
  typeof patternObj?.substringIndexVal === 'string' && patternObj?.substringIndexVal !== ''

const AdminAddSensorTypeModal = (props) => {
  const {
    parentSpaceId,
    edit = false,
    record = {},
    shouldUpdate
  } = props
  const parsedMetaData = Object
    .entries(JSON.parse(record?.metaData ?? '{}'))
    .reduce((acc, curr) => [
      ...acc,
      ...(Array.isArray(curr[1]) ? curr[1] : []).map(item => ({ object: curr[0], ...item }))
    ], [])
    .map((meta, i) => ({ ...meta, keyCount: i }))

  const { adminUpdateSensorType, adminAddSensorType } = useSensorTypes({ fetch: false })
  const { commands } = useAdminCommands()
  const { sensorSettings } = useAdminSensorSetting()

  const commandValues = commands.map(({ id }) => id)
  const defaultcommandValues = Array.isArray(record.Commands)
    ? record.Commands.map(({ id }) => id)
    : []
  const defaultSensorSettingGroupsValues = Array.isArray(record.SensorSettingGroups)
    ? record.SensorSettingGroups.map(({ id }) => id)
    : []
  const commandOptions = commands.map(({ displayName, command, id }) => ({
    label: `${displayName} (${command})`,
    value: id
  }))
  const sensorSettingGroupsOptions = sensorSettings.map(({ title, id }) => ({
    label: title,
    value: id
  }))
  const sensorSettingGroupsValues = sensorSettings.map(({ id }) => id)

  const [visible, setVisible] = useState(false)
  const [metaData, setMetaData] = useState(edit ? parsedMetaData : [])
  const metaKeyCount = useRef(/* @type {number} */(edit ? metaData.length : 0))
  const [form] = Form.useForm()
  const [indeterminate, setIndeterminate] = useState(false)
  const [indeterminateSensorSettingGroup, setIndeterminateSensorSettingGroup] = useState(false)

  const onChange = list => {
    setIndeterminate(!!list.length && list.length < commandOptions.length)
  }

  const onCheckAllChange = e => {
    form.setFieldsValue({ commands: e.target.checked ? commandValues : [] })
    setIndeterminate(false)
  }

  const onCheckAllSensorSettingGroupChange = e => {
    form.setFieldsValue({ settingGroups: e.target.checked ? sensorSettingGroupsValues : [] })
    setIndeterminateSensorSettingGroup(false)
  }

  const showModal = () => {
    setVisible(true)
  }

  const handleCancel = e => {
    message.destroy(parentSpaceId)
    form.resetFields()
    metaKeyCount.current = 0
    setVisible(false)
  }

  /**
   * @param {?boolean} add
   * @param {?number} index
   */
  const UpdateFields = (add, index) => {
    if (add) {
      setMetaData([...metaData, { ...METAOBJECT, keyCount: metaKeyCount.current }])
      metaKeyCount.current++
    } else {
      const list = [...metaData]

      if (typeof index === 'number') {
        list.splice(index, 1)
      } else {
        list.pop()
      }

      setMetaData(list)
    }
  }

  const handleChange = (i, key, value) => {
    metaData[i][key] = value
    setMetaData([...metaData])
  }

  const handleSubmit = item => {
    const { type, allowAutoUpgrade = false } = item

    console.log('item', item)

    const meta = metaData.reduce((r, a) => {
      r[a.object] = [...r[a.object] || [], a]
      return r
    }, {})

    if (!edit && !hasCustomNamePattern(item.namePatternConfig) && Object.hasOwnProperty.call(item || {}, 'namePatternConfig')) {
      item.namePatternConfig = null
    }

    const payload = {
      ...item,
      allowAutoUpgrade,
      type: parseInt(type),
      metaData: JSON.stringify(meta)
    }

    if (edit) {
      const editPayload = { ...record, id: record.id, ...payload }
      adminUpdateSensorType(editPayload)
        .then((res) => {
          form.resetFields()
          setVisible(false)
          shouldUpdate()
        }).catch(() => { })
    } else {
      adminAddSensorType(payload)
        .then((res) => {
          form.resetFields()
          setVisible(false)
          shouldUpdate()
        }).catch(() => { })
    }
  }

  const validateMessages = {
    required: "'${label}' is required!",
    types: {
      email: '${label} is not validate email!',
      number: '${label} is not a validate number!'
    }
  }

  return (
    <>
      {edit
        ? (
          <AntButton type='link' onClick={showModal}>
            Edit
          </AntButton>)
        : (
          <Button type='primary' onClick={showModal}>
            Add sensor type
          </Button>)}
      <Modal
        closable={false}
        visible={visible}
        destroyOnClose
        onCancel={handleCancel}
        bodyStyle={{ padding: '0' }}
        footer={[
          <FooterContainer key='buttons'>
            <ButtonContainer>
              <Button fill outline key='cancel' onClick={handleCancel}>
                Cancel
              </Button>
            </ButtonContainer>
            <ButtonContainer>
              <Button key='submit' type='primary' htmlType='submit' form='addSensorType'>
                {edit ? 'Update sensor type' : 'Add sensor type'}
              </Button>
            </ButtonContainer>
          </FooterContainer>
        ]}
      >
        <div>
          <TitleContainer>
            <h2>{edit ? 'Edit sensor type' : 'Add sensor type'}</h2>
          </TitleContainer>
          <FormContainer>
            <Form
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 14 }}
              layout='vertical'
              size='large'
              id='addSensorType'
              onFinish={handleSubmit}
              colon={false}
              form={form}
              validateMessages={validateMessages}
              initialValues={{
                ...record,
                commands: defaultcommandValues,
                settingGroups: defaultSensorSettingGroupsValues,
                admin: (record.admin),
                active: (record.active === 1),
                terms: (record.terms === 1),
                productId: record?.productId != null
                  ? JSON.stringify(record?.productId)
                  : null,
                alexaDisplayCategories: record?.alexaDisplayCategories != null
                  ? JSON.stringify(record?.alexaDisplayCategories)
                  : null,
              }}
            >
              {() => (
                <>
                  <Row>
                    <Form.Item
                      label='Name'
                      name='name'
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: 'Please add name'
                        }
                      ]}
                    >
                      <Input
                        placeholder='Name'
                        allowClear

                      />
                    </Form.Item>
                  </Row>
                  <Row>
                    <Form.Item
                      label='Description'
                      name='description'
                      hasFeedback
                    >
                      <Input.TextArea
                        placeholder='Description'
                        allowClear

                      />
                    </Form.Item>
                  </Row>
                  <Row>
                    <Form.Item
                      label='Type'
                      name='type'
                      required
                      rules={[
                        {
                          required: true
                        }
                      ]}
                    >
                      <Input
                        placeholder='Type'
                        allowClear
                      />
                    </Form.Item>
                    <Form.Item
                      label='FW version'
                      name='fwVersion'
                      hasFeedback
                      required
                      rules={[
                        {
                          required: true,
                          validator: (_, value) => {
                            if (valid(value) != null) return Promise.resolve()

                            return Promise.reject('Not a valid semver value')
                          }
                        }
                      ]}
                    >
                      <Input
                        placeholder='FW version'
                        allowClear
                      />
                    </Form.Item>
                  </Row>
                  <Row>
                    <Form.Item
                      label='Filename'
                      name='filename'
                      required
                      rules={[
                        {
                          required: true
                        }
                      ]}
                    >
                      <Input
                        placeholder='Filename'
                        allowClear
                      />
                    </Form.Item>
                  </Row>
                  {commandOptions.length > 0 && (
                    <>
                      <CheckAllRow>
                        <Form.Item label='Commands'>
                          <Checkbox
                            indeterminate={indeterminate}
                            onChange={onCheckAllChange}
                            checked={commandOptions.length === form.getFieldValue('commands')?.length}
                          >
                            Check all
                          </Checkbox>
                        </Form.Item>
                      </CheckAllRow>
                      <Row>
                        <CheckboxFormItem
                          name='commands'
                        >
                          <CheckboxGroup
                            options={commandOptions}
                            onChange={onChange}
                          />
                        </CheckboxFormItem>
                      </Row>
                    </>
                  )}
                  {sensorSettingGroupsOptions?.length > 0 && (
                    <>
                      <CheckAllRow>
                        <Form.Item label='Setting groups'>
                          <Checkbox
                            indeterminate={indeterminateSensorSettingGroup}
                            onChange={onCheckAllSensorSettingGroupChange}
                            checked={sensorSettingGroupsOptions.length === form.getFieldValue('settingGroups')?.length}
                          >
                            Check all
                          </Checkbox>
                        </Form.Item>
                      </CheckAllRow>
                      <Row>
                        <CheckboxFormItem
                          name='settingGroups'
                        >
                          <CheckboxGroup
                            options={sensorSettingGroupsOptions}
                            onChange={onChange}
                          />
                        </CheckboxFormItem>
                      </Row>
                    </>
                  )}
                  <Row>
                    <Form.Item
                      label='Allow auto upgrade'
                      name='allowAutoUpgrade'
                      valuePropName='checked'
                    >
                      <Switch />
                    </Form.Item>
                  </Row>
                  <Form.Item
                    label='Product ids'
                    name='productId'
                  >
                    <CodeEditor
                      defaultLanguage='json'
                      height='250px'
                    />
                  </Form.Item>
                  <Row>
                    <Form.Item
                      label='Google Device Type'
                      name='googleDeviceType'
                    >
                      <Input
                        placeholder='Google Device Type'
                        allowClear
                      />
                    </Form.Item>
                  </Row>
                  <Form.Item
                    label='Alexa Display Categories'
                    name='alexaDisplayCategories'
                  >
                    <CodeEditor
                      defaultLanguage='json'
                      height='250px'
                    />
                  </Form.Item>
                  <Form.Item
                    label='Pattern prefix'
                    name={['namePatternConfig', 'prefix']}
                    required={edit}
                    rules={[
                      {
                        required: edit,
                        type: 'string',
                      }
                    ]}
                  >
                    <Input
                      placeholder='Prefix'
                      allowClear
                    />
                  </Form.Item>
                  <Form.Item
                    label='Pattern substring index value'
                    name={['namePatternConfig', 'substringIndexVal']}
                    required={edit}
                    rules={[
                      {
                        required: edit,
                        type: 'string',
                      }
                    ]}
                  >
                    <Input
                      placeholder='Substring index value'
                      allowClear
                    />
                  </Form.Item>
                  <Form.Item
                    label='Pattern like'
                    name={['namePatternConfig', 'like']}
                    required={edit}
                    rules={[
                      {
                        required: edit,
                        type: 'string',
                      }
                    ]}
                  >
                    <Input
                      placeholder='Like'
                      allowClear
                    />
                  </Form.Item>
                  <Form.Item
                    label='Pattern order by substring index value'
                    name={['namePatternConfig', 'orderBySubstringIndexVal']}
                    required={edit}
                    rules={[
                      {
                        required: edit,
                        type: 'string'
                      }
                    ]}
                  >
                    <Input
                      placeholder='Order by substring index value'
                      allowClear
                    />
                  </Form.Item>
                  <Form.Item>
                    <Right>
                      <ColumnWrap>
                        <span>Add meta data</span>
                        <div style={{ display: 'flex' }}>
                          <Button fake outlined style={{ borderRadius: '10px 0 0 10px', minWidth: '25px' }} outline onClick={() => UpdateFields(false)}>
                            <MinusOutlined />
                          </Button>
                          <Button fake style={{ borderRadius: '0 10px 10px 0', minWidth: '25px' }} onClick={() => UpdateFields(true)}>
                            <PlusOutlined />
                          </Button>
                        </div>
                      </ColumnWrap>
                    </Right>
                  </Form.Item>
                  {metaData.map(({ object = '', key = '', displayName = '', keyCount }, i) => (
                    <Form.Item
                      label='Meta data object'
                      key={`${record?.id ?? ''}metaData${keyCount}`}
                    >
                      <Form.Item
                        required
                      >
                        <Input
                          type='input'
                          placeholder='Object name'
                          defaultValue={object}
                          value={object}
                          onChange={(e) => handleChange(i, 'object', e.target.value)}
                          allowClear
                        />
                      </Form.Item>
                      <Form.Item
                        required
                        key={`${record?.id ?? ''}metaDataDisplayName${keyCount}`}
                      >
                        <Input
                          type='input'
                          placeholder='Display name'
                          defaultValue={displayName}
                          value={displayName}
                          onChange={(e) => handleChange(i, 'displayName', e.target.value)}
                          allowClear
                        />
                      </Form.Item>
                      <Form.Item
                        required
                        key={`${record?.id ?? ''}metaDataKey${keyCount}`}
                      >
                        <Input
                          type='input'
                          placeholder='Key'
                          defaultValue={key}
                          value={key}
                          onChange={(e) => handleChange(i, 'key', e.target.value)}
                          allowClear
                        />
                      </Form.Item>
                      <AntButton
                        danger
                        type='link'
                        onClick={() => UpdateFields(false, i)}
                      >
                        Delete
                      </AntButton>
                    </Form.Item>
                  ))}
                </>
              )}
            </Form>
          </FormContainer>
        </div>
      </Modal>
    </>
  )
}

export default AdminAddSensorTypeModal
