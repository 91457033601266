import { routes, navigate } from '@redwoodjs/router'

import InfoCard from 'src/components/InfoCard'
import styled from 'styled-components'
import { Badge } from 'antd'
import * as c from 'src/constants'
import { useAuth, useIssues, useTenant, useUser } from 'src/hooks'

const CardContainer = styled.div`
  padding: 2em;
  display: grid;
  grid-template-columns: repeat(3, minmax(30%, 1fr));
  grid-gap: 2em;
  @media (max-width: ${c.SMALL_SCREEN}){
    grid-template-columns: repeat(1, minmax(90%, 1fr));
  }
`
const Content = styled.div`
  display: flex;
  justify-content: center;
  padding: 2em;
  min-width: 150px;
  display: flex;
  justify-content: center;
`

const ADMIN_CONTENT = [
  { name: 'Sensors', icon: 'thermometer', link: 'sensors', publicOption: true },
  { name: 'Sensor types', icon: 'list', link: 'sensorTypes' },
  { name: 'Machines', icon: 'microchip', link: 'machines', publicOption: true },
  { name: 'Users', icon: 'user', link: 'users', publicOption: true },
  { name: 'Spaces', icon: 'building', link: 'spaces', publicOption: true },
  { name: 'Algorithms', icon: 'wave-square', link: 'algorithms', publicOption: true },
  // @todo set publicOption back to true
  { name: 'Flows', icon: 'project-diagram', link: 'flows' },
  { name: 'Errors', icon: 'exclamation-triangle', link: 'errors', publicOption: true },
  { name: 'Logs', icon: 'file-contract', link: 'logs' },
  { name: 'Missing data', icon: 'database', link: 'missingData' },
  { name: 'Commands', icon: 'terminal', link: 'commands' },
  { name: 'Events', icon: 'sync', link: 'events' }
]

const AdminCards = () => {
  const { hasRole } = useAuth()
  const { getRoute, tenantId } = useTenant()
  const { user } = useUser()
  const { issueCount } = useIssues({ user })
  const StyledInfoCard = (props) => {
    return <InfoCard titleSize='2rem' clickable titleWeight='lighter' style={{ flex: '1', color: props.color, textAlign: 'center', justifyContent: 'center' }} {...props} />
  }

  return (
    <CardContainer>
      {ADMIN_CONTENT
        .filter(({ publicOption = false }) => tenantId
          ? (publicOption && hasRole(`tenant.${tenantId}.${c.ACCESS_WRITE}`))
          : hasRole('superAdmin')
        )
        .map(item => {
          const extraCount = item.name === 'Errors'
            ? (issueCount || null)
            : null
          return (
            <StyledInfoCard
              key={item.name}
              title={item.name}
              extra={
                extraCount && <Badge count={extraCount} overflowCount={9999} />
              }
              onClick={(e) => {
                navigate(getRoute('adminContent', { type: item.link }))
              }}
            >
              <Content unitColor='#7f9cf0' infoColor='#7f9cf0' numberColor='#233c84'>
                <i color='#FF631B' className={`fas fa-${item.icon}`} style={{ fontSize: '7em' }} />
              </Content>
            </StyledInfoCard>
          )
        })}
    </CardContainer>
  )
}

export default AdminCards
