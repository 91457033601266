import {
  Modal,
  Button as AntButton,
  Form,
  Input,
  Radio,
  message,
  Alert,
  Select,
  Switch,
  DatePicker,
  Divider,
  Popover
} from 'antd'

import { Fragment, useEffect, useState } from 'react'
import Button from 'src/components/Button'
import { useSensors } from 'src/hooks'
import QrScanner from 'src/components/QrScanner'
import styled from 'styled-components'
import * as c from '../../constants'
import ComponentFromObject from 'src/components/ComponentFromObject'
const { Option, OptGroup } = Select

const moment = require('moment')

const REGEX_MAC = /^(([A-Fa-f0-9]{2}[:]){5}[A-Fa-f0-9]{2}[,]?)+$/i

const ButtonContainer = styled.div`
  padding: 0.5em;
`
const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
`
const TitleContainer = styled.div`
  padding: 1em 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FF631B;
  & h2 {
    color: #fff;
  }
`
const FormContainer = styled.div`
  padding: 2em;
  & .ant-row.ant-form-item {
    flex: 1;
    justify-content: center;
    margin-bottom: 1em;
  }
  & .ant-col-14 {
    max-width: 100%;
  }
  & .ant-col-4 {
    padding-left: 8px;
    max-width: 100%;
    flex: 1;
  }
  & .ant-picker {
    width: 100%;
  }

  & .ant-divider{
    margin: 1em;
  }

  & .ant-form-large .ant-form-item-label > label {
    height: 25px;
  }
`

const RadioContainer = styled.div`
  display: flex;
  flex-direction: column;
  & .ant-radio-wrapper{
    display: flex;
    align-items: center;
    padding: 0.5em;
  }
  & p {
    margin: 0;
  }
`

const AlertContainer = styled.div`
  margin: 1em 0;
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  & .ant-form-item-control{
    padding: 0 0.5em;
  }
  @media (max-width: ${c.SMALL_SCREEN}){
    flex-direction: column;
  }
`

const AdvancedWrap = styled.div`
  display: flex;
  justify-content: flex-end;
`

const AdminAddSensorModal = (props) => {
  const {
    tableData,
    spaceId,
    edit = false,
    record = {},
    shouldUpdate,
    sensorTypes,
    users,
    spaces
  } = props

  const {
    addSensor,
    adminUpdateSensor
  } = useSensors({ fetch: false })
  const [timekey, setTimekey] = useState(Date.now())
  const [visible, setVisible] = useState(false)
  const [showQR, setShowQR] = useState(true)
  const [inputType, setInputType] = useState()
  const [cameraError, setCameraError] = useState()
  const [ShowNotificationEmail, setShowNotificiationEmail] = useState((record.notifications === 1))
  const [advanced, setAdvanced] = useState()
  const [buildings, setBuildings] = useState([])
  const [floors, setFloors] = useState([])
  const [rooms, setRooms] = useState([])
  const [form] = Form.useForm()

  const currentSensorType = sensorTypes.find(({ id }) => id === form.getFieldValue('sensorTypeId'))
  let formSettingIndex = 0

  useEffect(() => {
    if (visible) {
      sortSpaces(spaces)
      setTimekey(Date.now())
    }
  }, [spaces])

  const showModal = () => {
    setVisible(true)
  }

  const handleCancel = e => {
    message.destroy(timekey)
    form.resetFields()
    setAdvanced(false)
    setVisible(false)
  }

  const handleSubmit = item => {
    const { notifications, retired } = item
    const payload = {
      ...(edit && record) ? record : {},
      ...item,
      notifications: (notifications ? 1 : 0),
      retired: (retired ? 1 : 0)
    }
    if (edit) {
      const editPayload = { id: record.id, ...payload }
      adminUpdateSensor(editPayload)
        .then((res) => {
          form.resetFields()
          setVisible(false)
          shouldUpdate()
        }).catch(() => { })
    } else {
      addSensor(payload)
        .then((res) => {
          form.resetFields()
          setVisible(false)
          shouldUpdate()
        }).catch(() => { })
    }
  }

  const handleQR = code => {
    const codeSplit = code.split('-')
    form.setFieldsValue({ deviceId: codeSplit[0], name: codeSplit[1] })
    setShowQR(false)
  }

  const updateRadio = e => {
    message.destroy(timekey)
    setShowQR(true)
    const { target = {} } = e
    setInputType(target.value)
  }

  const changeNotification = (item) => {
    setShowNotificiationEmail(item)
  }

  const changeSetup = (item) => {
    setAdvanced(item)
  }

  const sortSpaces = (res) => {
    const GroupType = 'type'
    const GroupedSpaces = res.reduce((acc, item) => {
      if (!acc[item[GroupType]]) {
        acc[item[GroupType]] = []
      }

      acc[item[GroupType]].push(item)
      return acc
    }, {})
    setBuildings(GroupedSpaces[c.TYPE_BUILDING])
    setFloors(GroupedSpaces[c.TYPE_FLOOR])
    setRooms(GroupedSpaces[c.TYPE_ROOM])
  }

  const validateMessages = {
    required: "'${label}' is required!",
    types: {
      email: '${label} is not validate email!',
      number: '${label} is not a validate number!'
    }
  }

  return (
    <div onClick={(e) => e.stopPropagation()}>
      {edit
        ? (
          <AntButton type='link' onClick={showModal}>
            Edit
          </AntButton>)
        : (
          <Button type='primary' onClick={showModal}>
            Add sensor
          </Button>)}
      <Modal
        closable={false}
        visible={visible}
        destroyOnClose
        onCancel={handleCancel}
        bodyStyle={{ padding: '0' }}
        footer={[
          <FooterContainer key='buttons'>
            <ButtonContainer>
              <Button fill outline key='cancel' onClick={handleCancel}>
                Cancel
              </Button>
            </ButtonContainer>
            <ButtonContainer>
              <Button key='submit' type='primary' htmlType='submit' form='addSensor'>
                {edit ? 'Update sensor' : 'Add sensor'}
              </Button>
            </ButtonContainer>
          </FooterContainer>
        ]}
      >
        <div>
          <TitleContainer>
            <h2>{edit ? 'Edit sensor' : 'Add sensor'}</h2>
          </TitleContainer>
          <FormContainer>
            <Radio.Group
              onChange={(e) => updateRadio(e)}
              value={inputType}
              size='large'
              defaultValue={1}
            >
              <RadioContainer>
                <Radio value={1}>
                  <p>Fill in ID</p>
                </Radio>
                <Radio value={2}>
                  <p>Scan QR code</p>
                </Radio>
              </RadioContainer>
            </Radio.Group>
            {showQR && inputType && inputType === 2 &&
              (
                cameraError
                  ? (
                    <AlertContainer>
                      <Alert message='Camera error' description={cameraError.message || 'Unable to find camera'} type='error' showIcon />
                    </AlertContainer>)
                  : <QrScanner setData={handleQR} messageKey={timekey} showIcon setCameraError={setCameraError} />
              )}
            <Form
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 14 }}
              layout='vertical'
              size='large'
              id='addSensor'
              onFinish={handleSubmit}
              colon={false}
              form={form}
              validateMessages={validateMessages}
              initialValues={{
                ...record,
                placementDate: moment(record.placementDate),
                notifications: (record.notifications === 1),
                retired: (record.retired === 1)

              }}
            >
              <Form.Item
                label='NAME'
                name='name'
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'This field is required'
                  },
                  {
                    type: 'string',
                    message: 'This field needs to be a string'
                  },
                  {
                    min: 3,
                    message: 'This field needs to have at least 3 characters.'
                  },
                  {
                    max: 60,
                    message: 'This field can have max. 60 characters'
                  }
                ]}
              >
                <Input
                  placeholder='Sensor name'
                  allowClear

                />
              </Form.Item>
              <Form.Item
                label='DISPLAY NAME'
                name='displayName'
                hasFeedback
                rules={[
                  {
                    max: 255,
                    message: 'This field can have max. 255 characters'
                  }
                ]}
              >
                <Input
                  placeholder='Display name'
                  allowClear

                />
              </Form.Item>
              <Form.Item
                label='ID'
                name='deviceId'
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Please add sensor ID'
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || REGEX_MAC.test(value)) {
                        return Promise.resolve()
                      }
                      return Promise.reject('The sensor ID is not valid')
                    }
                  })
                ]}
              >
                <Input
                  placeholder='a1:b2:c3:d4:e5:f6'
                  allowClear
                  onChange={(e) => {
                    if (e.target.value === '') {
                      setShowQR(true)
                    }
                  }}
                />
              </Form.Item>
              <Form.Item
                label='Sensor type'
                name='sensorTypeId'
                required
              >
                <Select
                  showSearch
                  allowClear
                  placeholder='Select sensor type'
                  defaultValue={undefined}
                  filterOption={(input, option) => {
                    return (
                      option?.value != null
                      && option.value == input
                    )
                    || (
                      typeof option?.value === 'string'
                      && typeof input === 'string'
                      && option.value.toLowerCase().includes(input.toLowerCase())
                    )
                    || (
                      option?.children?.props?.children != null
                      && option?.children?.props?.children == input
                    )
                    || (
                      typeof option?.children?.props?.children === 'string'
                      && typeof input === 'string'
                      && option.children.props.children.toLowerCase().includes(input.toLowerCase())
                    )
                  }}
                >
                  {sensorTypes && sensorTypes.map(sensorType => {
                    return (
                      <Option key={sensorType.id} value={sensorType.id}>
                        <Popover placement='left' title={sensorType.filename} content={sensorType.description}>
                          {sensorType.name}
                        </Popover>
                      </Option>)
                  }
                  )}
                </Select>
              </Form.Item>
              <AdvancedWrap>
                <p>Advanced setup</p>
                <Switch
                  checkedChildren='1' unCheckedChildren='0'
                  style={{ margin: '0 1em' }}
                  onChange={(state) => changeSetup(state)}
                />
              </AdvancedWrap>

              {advanced &&
                <>
                  {currentSensorType != null && currentSensorType?.SensorSettingGroups?.length > 0 && (
                    <>
                      <Divider />
                      {currentSensorType?.SensorSettingGroups?.map(({ id: groupId, title, userSettings }) => (
                        <Fragment key={`group${groupId}`}>
                          <span>{title}</span>
                          {userSettings
                            ?.map(({ id: groupSettingId, label, uiComponent, type, SensorSettingPresets }) => {
                              const itemName = ['formSettings', formSettingIndex]

                              const presetOptions = SensorSettingPresets?.map(preset => ({ value: preset.id, label: preset.label }))

                              formSettingIndex++

                              return (
                                <Fragment key={`groupSetting${groupSettingId}`}>
                                  <Form.Item
                                    label={label}
                                    name={[...itemName, 'SensorConfigurableSettingId']}
                                    hidden
                                    initialValue={groupSettingId}
                                  >
                                    <Input />
                                  </Form.Item>
                                  {type === 'preset'
                                    ? (
                                      <Form.Item
                                        key={`groupSetting${groupSettingId}`}
                                        label={label}
                                        name={[...itemName, 'SensorSettingPresetId']}
                                      >
                                        <Select
                                          options={presetOptions}
                                        />
                                      </Form.Item>
                                    )
                                    : (
                                      <ComponentFromObject
                                        key={`groupSetting${groupSettingId}`}
                                        componentObject={c.SENSOR_CONFIG_COMPONENTS}
                                        component={uiComponent}
                                        WrapComponent={Form.Item}
                                        label={label}
                                        name={[...itemName, 'value']}
                                      />
                                    )}
                                </Fragment>
                              )
                            })}
                        </Fragment>
                      ))}
                    </>
                  )}
                  <Divider />
                  <Row>
                    <Form.Item
                      label='Owner'
                      name='owner'
                    >
                      <Select
                        showSearch
                        allowClear
                        placeholder='Select sensor Owner'
                        defaultValue={undefined}
                        filterOption={(input, option) =>
                          option && option.children && option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      >
                        {users.map(user => {
                          return <Option key={user.id} value={user.id}>{`${user.id} - ${user.displayname}`}</Option>
                        }
                        )}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label='Location'
                      name='location'
                    >
                      <Input
                        placeholder='Location'
                        allowClear
                      />
                    </Form.Item>
                  </Row>
                  <Row>
                    <Form.Item
                      label='Story'
                      name='story'
                    >
                      <Input.TextArea
                        placeholder='Story'
                        allowClear
                      />
                    </Form.Item>
                  </Row>
                  <Row>
                    <Form.Item
                      label='Address'
                      name='address'
                    >
                      <Input
                        placeholder='Address'
                        allowClear
                      />
                    </Form.Item>
                    <Form.Item
                      label='Placement date'
                      name='placementDate'
                    >
                      <DatePicker allowClear />
                    </Form.Item>
                  </Row>
                  <Row>
                    <Form.Item
                      label='Notifications'
                      name='notifications'
                      valuePropName='checked'
                    >
                      <Switch
                        checkedChildren='1' unCheckedChildren='0'
                        onChange={(state) => changeNotification(state)}
                      />
                    </Form.Item>
                    <Form.Item
                      label='Notification Email'
                      name='notificationEmail'
                      rules={[{ type: 'email' }]}
                      hidden={!ShowNotificationEmail}
                    >
                      <Input
                        placeholder='Notification Email'
                        allowClear
                      />
                    </Form.Item>
                  </Row>
                  <Divider />
                  <Row>
                    <Form.Item
                      label='Type'
                      name='type'
                    >
                      <Input
                        placeholder='Type'
                        allowClear
                      />
                    </Form.Item>
                  </Row>
                  <Row>
                    <Form.Item
                      label='Retired'
                      name='retired'
                      valuePropName='checked'
                    >
                      <Switch
                        checkedChildren='1' unCheckedChildren='0'
                      />
                    </Form.Item>
                    <Form.Item
                      label='TCF'
                      name='tcf'
                    >
                      <Input
                        placeholder='Tcf'
                        allowClear
                      />
                    </Form.Item>
                    <Form.Item
                      label='Batch ID'
                      name='batchId'
                    >
                      <Input
                        placeholder='Batch ID'
                        allowClear
                      />
                    </Form.Item>
                  </Row>
                  <Row>
                    <Form.Item
                      label='Config'
                      name='config'
                    >
                      <Input.TextArea
                        placeholder='Config'
                        allowClear
                      />
                    </Form.Item>
                  </Row>
                  <Row>
                    <Form.Item
                      label='Parent sensor'
                      name='parentSensorId'
                    >
                      <Select
                        showSearch
                        allowClear
                        placeholder='Select parent sensor'
                        defaultValue={undefined}
                        filterOption={(input, option) =>
                          option && option.children && option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      >
                        {tableData && tableData.map(sensor => {
                          return (
                            <Option
                              key={sensor.id}
                              value={sensor.id}
                            >{`${sensor.id} - ${sensor.name}`}
                            </Option>
                          )
                        }
                        )}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label='Space ID'
                      name='spaceId'
                    >
                      <Select
                        showSearch
                        allowClear
                        placeholder='Select a Room'
                        optionFilterProp='rooms'
                        defaultValue={spaceId || undefined}
                        filterOption={(input, option) =>
                          option && option.children && option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      >
                        {buildings && buildings.map(building => {
                          return floors.map(floor => {
                            if (floor.parentSpaceId === building.id) {
                              return (
                                <OptGroup key={floor.id} label={`${building.name} - ${floor.name}`}>
                                  {rooms.map((room, i) => {
                                    if (room.parentSpaceId === floor.id) {
                                      return <Option key={room.id} value={room.id}>{room.name}</Option>
                                    }
                                  })}
                                </OptGroup>
                              )
                            }
                          }
                          )
                        })}
                      </Select>
                    </Form.Item>
                  </Row>
                </>}
            </Form>
          </FormContainer>
        </div>
      </Modal>
    </div>
  )
}

export default AdminAddSensorModal
