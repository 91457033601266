import {
  Modal,
  Button as AntButton,
  Form,
  Input,
  Upload,
  Progress
} from 'antd'
import {
  MinusOutlined,
  PlusOutlined,
  InboxOutlined,
  DeleteOutlined,
  CheckCircleOutlined
} from '@ant-design/icons'

import { Fragment, useEffect, useRef, useState } from 'react'
import Button from 'src/components/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import styled from 'styled-components'
import * as c from '../../constants'
import { useAsset, usePlugins } from 'src/hooks'

const ButtonContainer = styled.div`
  padding: 0.5em;
`
const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
`
const TitleContainer = styled.div`
  padding: 1em 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FF631B;
  & h2 {
    color: #fff;
  }
`
const FormContainer = styled.div`
  padding: 2em;
  & .ant-row.ant-form-item {
    flex: 1;
    justify-content: center;
    margin-bottom: 1em;
  }
  & .ant-col-14 {
    max-width: 100%;
  }
  & .ant-col-4 {
    padding-left: 8px;
    max-width: 100%;
    flex: 1;
  }
  & .ant-picker {
    width: 100%;
  }

  & .ant-divider{
    margin: 1em;
  }

  & .ant-form-large .ant-form-item-label > label {
    height: 25px;
  }
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  & .ant-form-item-control{
    padding: 0 0.5em;
  }
  .ant-form-item-control-input-content{
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  @media (max-width: ${c.SMALL_SCREEN}){
    flex-direction: column;
  }
`

const Right = styled.div`
  display: flex;
  justify-content: flex-end;
  & button {
    min-width: 60px;
  }
`

const ColumnWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StyledDragger = styled(Upload.Dragger)`
  width: 100%;
`

const UploadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  & > * {
    margin-bottom: 1rem;
  }
`

const PreviewImage = styled.img`
  /* flex: 1; */
  width: 128px;
  height: 128px;
  border-radius: 1rem;
  box-shadow: 0px 10px 25px rgba(0,0,0,0.2);
  object-fit: scale-down;
  ${props => props.isEmpty && css`
    background-color: gray;
    outline: none;
    border: none;
  `}
`

const FileListContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1em;
`

const FileNameWrap = styled.div`
  display: flex;
  & span {
    justify-content: center;
    align-items: center;
    display: flex;
    margin: 0 0.5em;
  }
`

/**
 * @typedef UserSettings
 * @type {object}
 * @property {number} key - The key
 * @property {string} name - The name
 * @property {string} type - The type
 */

/**
 * @typedef AdminSettings
 * @type {object}
 * @property {number} key - The key
 * @property {string} label - The label
 * @property {string} value - The value
 */

/**
 * @param {*} record
 * @returns {UserSettings[]}
 */
const _getUserDataFromRecord = ({ config = {} }) => config?.user?.reduce((acc, curr) => [
  ...acc,
  ...Object.values(curr)
], [])
  .map((item, i) => ({ ...item, key: i })) || []

/**
 * @param {*} record
 * @returns {AdminSettings[]}
 */
const _getAdminDataFromRecord = ({ config = {} }) => {
  return Object.keys(config)
    .filter((key) => typeof config[key] !== 'object')
    .map((key, i) => ({ label: key, value: config[key], key: i }))
}

const AdminAddMachineModal = (props) => {
  const {
    tableData,
    edit = false,
    record = {},
    shouldUpdate
  } = props

  const { adminAddMachine, adminUpdateMachine } = usePlugins({ fetch: false })

  const [configSettings, setConfigSettings] = useState((edit ? _getUserDataFromRecord(record) : []))
  const [adminSettings, setAdminSettings] = useState(edit ? _getAdminDataFromRecord(record) : [])

  const [visible, setVisible] = useState(false)
  const [form] = Form.useForm()

  const adminKeyCount = useRef(/* @type {number} */(edit ? adminSettings.length : 0))
  const userKeyCount = useRef(/* @type {number} */(edit ? configSettings.length : 0))
  const { getPreview, beforeUpload, customRequest, deleteAsset, uploadStatus, progressUpload, fileList, urlToImage } = useAsset({
    fileList: edit && record?.coverImage
      ? [{ name: record?.name, ImageUrl: record?.imageUrl }]
      : []
  })

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e
    }
    return e && e.fileList
  }

  const showModal = () => {
    setVisible(true)
  }

  /**
   * @param {React.FormEvent<HTMLFormElement>} e
   */
  const handleCancel = e => {
    deleteAsset()
    form.resetFields()
    userKeyCount.current = 0
    adminKeyCount.current = 0
    setVisible(false)
  }

  const handleSubmit = obj => {
    const payload = {
      ...edit ? record : {},
      configurableSettings: JSON.stringify({
        ...edit ? record?.config || {} : {},
        ...adminSettings.reduce((acc, { label: name, ...curr } = {}) => ({
          ...acc,
          [name]: { name, ...curr }
        }), {}),
        user: [
          ...configSettings.map(config => {
            delete config.key
            return { [config.name]: config }
          })
        ]
      }),
      name: obj.name,
      coverImage: urlToImage
    } // Try to read this with love from Linus

    if (edit) {
      adminUpdateMachine(record.id, payload)
        .then((res) => {
          form.resetFields()
          setVisible(false)
          shouldUpdate()
        })
    } else {
      adminAddMachine(payload)
        .then((res) => {
          form.resetFields()
          setVisible(false)
          shouldUpdate()
        })
    }
  }

  /**
   * @param {boolean} admin
   * @param {number} index
   * @param {string} key
   * @param {string} value
   * @returns {void}
   */
  const handleChange = (admin, index, key, value) => {
    if (admin) {
      if (index >= adminSettings.length) return
      const newValue = [...adminSettings]
      newValue[index][key] = value

      setAdminSettings(newValue)
    } else {
      if (index >= configSettings.length) return
      const newValue = [...configSettings]
      newValue[index][key] = value

      setConfigSettings(newValue)
    }
  }

  /**
   * @param {boolean} add
   * @param {number} index
   */
  const UpdateFields = (add, index) => {
    if (add) {
      setConfigSettings([...configSettings, { name: '', type: '', key: userKeyCount.current }])
      userKeyCount.current++
    } else {
      const list = configSettings

      if (typeof index === 'number') {
        list.splice(index, 1)
      } else {
        list.pop()
      }

      setConfigSettings([...list])
    }
  }

  /**
   * @param {boolean} add
   * @param {number} index
   */
  const UpdateAdminFields = (add, index) => {
    if (add) {
      setAdminSettings([...adminSettings, { label: '', value: '', key: adminKeyCount.current }])
      adminKeyCount.current++
    } else {
      const list = adminSettings

      if (typeof index === 'number') {
        const deleted = list.splice(index, 1)
        if (edit) delete record.config[deleted[0].label]
      } else {
        const deleted = list.pop()
        if (edit) delete record.config[deleted.label]
      }

      setAdminSettings([...list])
    }
  }

  /* eslint-disable no-template-curly-in-string */
  const validateMessages = {
    required: "'${label}' is required!",
    types: {
      email: '${label} is not validate email!',
      number: '${label} is not a validate number!'
    }
  }
  /* eslint-enable no-template-curly-in-string */

  return (
    <div onClick={(e) => e.stopPropagation()}>
      {edit
        ? (
          <AntButton type='link' onClick={showModal}>
            Edit
          </AntButton>)
        : (
          <Button type='primary' onClick={showModal}>
            Add machine
          </Button>)}
      <Modal
        closable={false}
        visible={visible}
        destroyOnClose
        onCancel={handleCancel}
        bodyStyle={{ padding: '0' }}
        footer={[
          <FooterContainer key='buttons'>
            <ButtonContainer>
              <Button fill outline key='cancel' onClick={handleCancel}>
                Cancel
              </Button>
            </ButtonContainer>
            <ButtonContainer>
              <Button key='submit' type='primary' htmlType='submit' form='addMachine'>
                {edit ? 'Update machine' : 'Add machine'}
              </Button>
            </ButtonContainer>
          </FooterContainer>
        ]}
      >
        <div>
          <TitleContainer>
            <h2>{edit ? 'Edit machine' : 'Add machine'}</h2>
          </TitleContainer>
          <FormContainer>
            <Form
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 14 }}
              layout='vertical'
              size='large'
              id='addMachine'
              onFinish={handleSubmit}
              colon={false}
              form={form}
              validateMessages={validateMessages}
              initialValues={{
                ...record
              }}
            >
              {getPreview() && (
                <Form.Item>
                  <UploadWrapper>
                    <PreviewImage
                      src={getPreview()}
                    />
                  </UploadWrapper>
                </Form.Item>
              )}
              <Form.Item
                label='Machine name'
                name='name'
                required
                rules={[
                  {
                    required: true,
                    message: 'Please set a name.'
                  }
                ]}
              >
                <Input />
              </Form.Item>
              <UploadWrapper>
                <Form.Item
                  name='coverImageContainer'
                  valuePropName='fileList'
                  getValueFromEvent={normFile}
                  noStyle
                >
                  <StyledDragger
                    name='file'
                    customRequest={customRequest}
                    beforeUpload={beforeUpload}
                    showUploadList={false}
                  >
                    <p className='ant-upload-drag-icon'>
                      <InboxOutlined />
                    </p>
                    <p className='ant-upload-text'>
                      Click or drag an image of the machine to this area to upload.
                    </p>
                  </StyledDragger>
                </Form.Item>
                {progressUpload && (
                  <Progress

                    status={uploadStatus ? 'active' : 'exception'}
                    percent={progressUpload}
                  />
                )}
                {fileList.map(({ name }, i) => (
                  <FileListContainer key={i}>
                    <FileNameWrap>
                      <CheckCircleOutlined />
                      <a>{name}</a>
                    </FileNameWrap>
                    <DeleteOutlined onClick={deleteAsset} />
                  </FileListContainer>
                ))}
              </UploadWrapper>
              <Form.Item>
                <Right>
                  <ColumnWrap>
                    <span>Add admin settings</span>
                    <div style={{ display: 'flex' }}>
                      <Button fake outlined style={{ borderRadius: '10px 0 0 10px', minWidth: '25px' }} outline onClick={() => UpdateAdminFields(false)}>
                        <MinusOutlined />
                      </Button>
                      <Button fake style={{ borderRadius: '0 10px 10px 0', minWidth: '25px' }} onClick={() => UpdateAdminFields(true)}>
                        <PlusOutlined />
                      </Button>
                    </div>
                  </ColumnWrap>
                </Right>
              </Form.Item>
              {adminSettings.map(({ label = '', value = '', key }, i) => {
                return (
                  <Fragment key={`${key}^@^adminSettings`}>
                    <Form.Item
                      name={`${key}^@^label`}
                      key={`${key}^@^label`}
                      label='Admin settings'
                      required
                    >
                      <Input
                        type='input'
                        placeholder='Name'
                        onChange={({ target }) => handleChange(true, i, 'label', target.value)}
                        defaultValue={label}
                        value={label}
                        allowClear
                      />
                    </Form.Item>
                    <Form.Item
                      name={`${key}^@^value`}
                      key={`${key}^@^value`}
                      required
                    >
                      <Input
                        type='input'
                        placeholder='Value'
                        onChange={({ target }) => handleChange(true, i, 'value', target.value)}
                        defaultValue={value}
                        value={value}
                        allowClear
                      />
                    </Form.Item>
                    <AntButton type='link' onClick={() => UpdateAdminFields(false, i)}>
                      Delete
                    </AntButton>
                  </Fragment>
                )
              })}
              <Form.Item>
                <Right>
                  <ColumnWrap>
                    <span>Add config settings</span>
                    <div style={{ display: 'flex' }}>
                      <Button fake outlined style={{ borderRadius: '10px 0 0 10px', minWidth: '25px' }} outline onClick={() => UpdateFields(false)}>
                        <MinusOutlined />
                      </Button>
                      <Button fake style={{ borderRadius: '0 10px 10px 0', minWidth: '25px' }} onClick={() => UpdateFields(true)}>
                        <PlusOutlined />
                      </Button>
                    </div>
                  </ColumnWrap>
                </Right>
              </Form.Item>
              {configSettings.map(({ name = '', type = '', key }, i) => {
                return (
                  <Fragment key={`${key}@^@configSettings`}>
                    <Form.Item
                      name={`${key}@^@name`}
                      label='Configure settings'
                      required
                    >
                      <Input
                        type='input'
                        placeholder='Name'
                        onChange={({ target }) => handleChange(false, i, 'name', target.value)}
                        defaultValue={name}
                        value={name}
                        allowClear
                      />
                    </Form.Item>
                    <Form.Item
                      name={`${key}@^@type`}
                      required
                    >
                      <Input
                        type='input'
                        placeholder='Type'
                        onChange={({ target }) => handleChange(false, i, 'type', target.value)}
                        defaultValue={type}
                        value={type}
                        allowClear
                      />
                    </Form.Item>
                    <AntButton type='link' onClick={() => UpdateFields(false, i)}>
                      Delete
                    </AntButton>
                  </Fragment>)
              })}
            </Form>
          </FormContainer>
        </div>
      </Modal>
    </div>
  )
}

export default AdminAddMachineModal
