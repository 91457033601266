import React, { useState } from 'react'
import {
  Modal,
  Button as AntButton,
  Form,
  Input,
  Switch,
  Collapse,
  Select
} from 'antd'
import { DeleteFilled } from '@ant-design/icons'
import styled from 'styled-components'

import Button from 'src/components/Button'
import { useAdminCommands } from 'src/hooks'
import * as c from '../../constants'

const ButtonContainer = styled.div`
  padding: 0.5em;
`
const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
`
const TitleContainer = styled.div`
  padding: 1em 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FF631B;
  & h2 {
    color: #fff;
  }
`
const FormContainer = styled.div`
  padding: 2em;
  & .ant-row.ant-form-item {
    flex: 1;
    justify-content: center;
    margin-bottom: 1em;
  }
  & .ant-col-14 {
    max-width: 100%;
  }
  & .ant-col-4 {
    padding-left: 8px;
    max-width: 100%;
    flex: 1;
  }
  & .ant-picker {
    width: 100%;
  }

  & .ant-divider{
    margin: 1em;
  }

  & .ant-form-large .ant-form-item-label > label {
    height: 25px;
  }
`

const AddParamBtnContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 1em;
`

const Panel = styled(Collapse.Panel)`
  box-shadow: 3px 3px 10px 0 rgb(0 0 0 / 10%);
  margin-bottom: 1em;

  &.ant-collapse-item,
  &.ant-collapse-item:last-child {
    border-radius: 0.5rem;
  }
`

/**
 * @typedef AdminAddParamModalProps
 * @type {object}
 *
 * @param {AdminAddParamModalProps} props
 * @returns {React.ReactNode}
 */

const AdminAddParamUiModal = ({
  edit = false,
  record = {},
  shouldUpdate,
  commandId
}) => {
  const { addCommandParamUi, updateCommandParamUi } = useAdminCommands({ fetch: false, id: commandId })
  const [visible, setVisible] = useState(false)
  const [form] = Form.useForm()

  const showModal = () => {
    setVisible(true)
  }

  const handleCancel = e => {
    form.resetFields()
    setVisible(false)
  }

  const handleSubmit = async item => {
    item?.params?.forEach?.((param, i) => {
      if (param.adminParam) {
        Object.keys(param).forEach(paramKey => {
          if (paramKey !== 'value') {
            delete param[paramKey]
          }
        })
      } else {
        delete param.value
      }

      delete param.newParam
      delete param.adminParam
    })

    if (edit) {
      await updateCommandParamUi(record.id, item)
    } else {
      await addCommandParamUi(item)
    }

    form.resetFields()
    form.setFieldsValue({ params: undefined })
    setVisible(false)
    shouldUpdate?.()
  }

  const validateMessages = {
    required: "'${label}' is required!",
    types: {
      email: '${label} is not validate email!',
      number: '${label} is not a validate number!'
    }
  }

  const renderAdminParamFields = field => (
    <>
      <Form.Item
        {...field}
        label='Value'
        name={[field.name, 'value']}
        fieldKey={[field.fieldKey, 'value']}
      >
        <Input />
      </Form.Item>
    </>
  )

  const renderUserParamFields = field => (
    <>
      <Form.Item
        {...field}
        label='Default value'
        name={[field.name, 'defaultValue']}
        fieldKey={[field.fieldKey, 'defaultValue']}
      >
        <Input />
      </Form.Item>
      <Form.Item
        {...field}
        label='Ui component'
        name={[field.name, 'uiComponent']}
        fieldKey={[field.fieldKey, 'uiComponent']}
        initialValue='Input'
      >
        <Select
          options={c.COMMAND_PARAM_USER_UI_OPTIONS}
        />
      </Form.Item>
      <Form.Item
        {...field}
        label='Label'
        name={[field.name, 'label']}
        fieldKey={[field.fieldKey, 'label']}
      >
        <Input />
      </Form.Item>
      <Form.Item
        {...field}
        label='Description'
        name={[field.name, 'description']}
        fieldKey={[field.fieldKey, 'description']}
      >
        <Input />
      </Form.Item>
    </>
  )

  const renderDeleteBtn = clickHandler => (
    <DeleteFilled
      style={{
        color: '#d9363e'
      }}
      onClick={e => {
        e.stopPropagation()

        clickHandler()
      }}
    />
  )

  return (
    <>
      {edit
        ? (
          <AntButton type='link' onClick={showModal}>
        Edit
          </AntButton>)
        : (
          <Button type='primary' onClick={showModal}>
        Add param ui
          </Button>)}
      <Modal
        closable={false}
        visible={visible}
        destroyOnClose
        onCancel={handleCancel}
        bodyStyle={{ padding: '0' }}
        footer={[
          <FooterContainer key='buttons'>
            <ButtonContainer>
              <Button fill outline key='cancel' onClick={handleCancel}>
              Cancel
              </Button>
            </ButtonContainer>
            <ButtonContainer>
              <Button key='submit' type='primary' htmlType='submit' form='addParamUi'>
                {edit ? 'Update param ui' : 'Add param ui'}
              </Button>
            </ButtonContainer>
          </FooterContainer>
        ]}
      >
        <div>
          <TitleContainer>
            <h2>{edit ? 'Edit param ui' : 'Add param ui'}</h2>
          </TitleContainer>
          <FormContainer>
            <Form
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 14 }}
              layout='vertical'
              size='large'
              id='addParamUi'
              onFinish={handleSubmit}
              colon={false}
              form={form}
              validateMessages={validateMessages}
              initialValues={{
                ...edit
                  ? record
                  : {}
              }}
            >
              {() => (
                <>
                  <Form.Item
                    label='Title'
                    name='title'
                  >
                    <Input
                      placeholder='Title'
                      allowClear
                    />
                  </Form.Item>
                  <Form.Item
                    label='Button name'
                    name='buttonName'
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: 'Please add a button name'
                      }
                    ]}
                  >
                    <Input
                      placeholder='Button name'
                      allowClear
                    />
                  </Form.Item>
                  <Form.Item
                    label='Button icon'
                    name='buttonIcon'
                  >
                    <Input
                      placeholder='Button icon'
                      allowClear
                    />
                  </Form.Item>
                  <Form.Item
                    label='Description'
                    name='description'
                    hasFeedback
                  >
                    <Input.TextArea
                      placeholder='Description'
                      allowClear
                    />
                  </Form.Item>
                  <Form.List name='params'>
                    {(fields, { add, remove }) => (
                      <>
                        <Collapse defaultActiveKey={[fields?.[fields.length - 1]?.key]} key={fields.length} ghost>
                          {fields.map((field, i) => {
                            const adminParam = form.getFieldValue(['params', field.name, 'adminParam'])
                            const newParam = form.getFieldValue(['params', field.name, 'newParam'])
                            const adminValue = form.getFieldValue(['params', field.name, 'value'])
                            const paramPreview = newParam || adminValue || adminParam
                              ? adminValue
                              : '{user_input}'

                            return (
                              <Panel
                                header={`Param ${i + 1}${paramPreview ? ` (${paramPreview})` : ''}`}
                                key={field.key}
                                extra={renderDeleteBtn(() => {
                                  remove(i)
                                })}
                                forceRender
                              >
                                <Form.Item
                                  label='Admin param'
                                  initialValue={newParam || adminValue}
                                  name={[field.name, 'adminParam']}
                                  valuePropName='checked'
                                >
                                  <Switch
                                    checkedChildren='1' unCheckedChildren='0'
                                  />
                                </Form.Item>
                                {(
                                  adminParam
                                    ? renderAdminParamFields(field)
                                    : renderUserParamFields(field)
                                )}
                              </Panel>
                            )
                          })}
                        </Collapse>

                        <AddParamBtnContainer>
                          <Button
                            onClick={e => {
                              e.preventDefault()

                              add({ newParam: true })
                            }}
                          >
                            Add param
                          </Button>
                        </AddParamBtnContainer>
                      </>
                    )}
                  </Form.List>
                </>
              )}
            </Form>
          </FormContainer>
        </div>
      </Modal>
    </>
  )
}

export default AdminAddParamUiModal
