import {
  Modal,
  Button as AntButton,
  Form,
  Input,
  Select,
  Spin,
  Alert,
  Radio,
  Divider,
  Space
} from 'antd'

import { useEffect, useState } from 'react'
import Button from 'src/components/Button'
import LoadingScreen from 'src/components/LoadingScreen'
import { useSensorUser, useAdminAllSensors } from 'src/hooks'

import styled from 'styled-components'
import * as c from 'src/constants'

const { Option } = Select

const ButtonContainer = styled.div`
  padding: 0.5em;
`
const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
`
const TitleContainer = styled.div`
  padding: 1em 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FF631B;
  & h2 {
    color: #fff;
  }
`
const FormContainer = styled.div`
  padding: 2em;
  & .ant-row.ant-form-item {
    flex: 1;
    justify-content: center;
    margin-bottom: 1em;
  }
  & .ant-col-14 {
    max-width: 100%;
  }
  & .ant-col-4 {
    padding-left: 8px;
    max-width: 100%;
    flex: 1;
  }
  & .ant-picker {
    width: 100%;
  }

  & .ant-divider{
    margin: 1em;
  }

  & .ant-form-large .ant-form-item-label > label {
    height: 25px;
  }
`

const UserSensorAccessModal = (props) => {
  const {
    userSensors,
    userId,
    edit = false,
    refresh,
    children
  } = props

  const { adminAddSensorToUser } = useSensorUser()
  const {
    sensors,
    loading,
    searchState,
    paginationState,
  } = useAdminAllSensors({
    paginationAppend: true
  })
  const [visible, setVisible] = useState(false)
  const [form] = Form.useForm()

  const showModal = () => {
    setVisible(true)
  }

  const handleCancel = e => {
    form.resetFields()
    setVisible(false)
  }

  const handleSubmit = obj => {
    const { sensorId } = obj
    const { key } = sensorId
    const payload = { userId: userId, sensorId: key }
    adminAddSensorToUser(payload)
      .then((res) => {
        form.resetFields()
        setVisible(false)
        refresh()
      }).catch(() => {})
  }

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <div onClick={showModal}>
        {children ||
        (
          <Button type='primary'>
        Add sensor
          </Button>
        )}
      </div>
      {visible && loading && !sensors
        ? <LoadingScreen />
        : (
          <Modal
            closable={false}
            visible={visible}
            destroyOnClose
            onCancel={handleCancel}
            bodyStyle={{ padding: '0' }}
            footer={[
              <FooterContainer key='buttons'>
                <ButtonContainer>
                  <Button fill outline key='cancel' onClick={handleCancel}>
              Cancel
                  </Button>
                </ButtonContainer>
                <ButtonContainer>
                  <Button key='submit' type='primary' htmlType='submit' form='addMachine'>
                    {edit ? 'Update sensor' : 'Add sensor'}
                  </Button>
                </ButtonContainer>
              </FooterContainer>
            ]}
          >
            <div>
              <TitleContainer>
                <h2>{edit ? 'Edit sensor' : 'Add sensor'}</h2>
              </TitleContainer>
              <FormContainer>
                <Form
                  labelCol={{ span: 4 }}
                  wrapperCol={{ span: 14 }}
                  layout='vertical'
                  size='large'
                  id='addMachine'
                  onFinish={handleSubmit}
                  colon={false}
                  form={form}
                >
                  {/* <Form.Item
                    name='accessLevel'
                    label='Select accessLevel'
                    rules={[{ required: true, message: 'Please select an access level' }]}
                  >
                    <Radio.Group>
                      <RadioButton value={c.SPACE_USER}>User</RadioButton>
                      <RadioButton value={c.SPACE_INSTALLER}>Installer</RadioButton>
                      <RadioButton value={c.SPACE_OWNER}>Owner</RadioButton>
                    </Radio.Group>
                  </Form.Item> */}
                  <Form.Item
                    label='Select sensor'
                    name='sensorId'
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: `Please select a sensor to add to user ${userId}`
                      }]}
                  >
                    {sensors
                      ? (
                        <Select
                          labelInValue
                          placeholder='Select sensor'
                          notFoundContent={null}
                          style={{ width: '100%' }}
                          showSearch
                          allowClear
                          searchValue={searchState.value}
                          onSearch={searchState.setValue}
                          optionFilterProp='children'
                          filterOption={false}
                          options={sensors.map(sensor => {
                            const found = userSensors.some(userSensor => userSensor.id === sensor.id)
                            return { label: sensor.formattedName, value: sensor.id, disabled: found }
                          })}
                          dropdownRender={menu => (
                            <>
                              {loading
                                ? (
                                  <Space
                                    direction='vertical'
                                    align="center"
                                    style={{
                                      padding: '8px 4px',
                                      width: '100%'
                                    }}
                                  >
                                    <Spin />
                                  </Space>
                                )
                                : menu
                              }
                              {paginationState.pageInfo.hasNextPage && !loading && (
                                <>
                                  <Divider style={{ margin: '8px 0' }} />
                                  <Space
                                    direction='vertical'
                                    align="center"
                                    style={{
                                      padding: '0 8px 4px',
                                      width: '100%'
                                    }}
                                  >
                                    <Button
                                      onClick={paginationState.goToNextPage}
                                      disabled={loading}
                                    >
                                      Load more
                                    </Button>
                                  </Space>
                                </>
                              )}
                            </>
                          )}
                        />
                      )
                      : (
                        <Alert
                          message='Warning'
                          description='No sensors found'
                          type='error'
                          showIcon
                        />)}
                  </Form.Item>
                </Form>
              </FormContainer>
            </div>
          </Modal>
        )}
    </div>
  )
}

export default UserSensorAccessModal
