
import { Card } from 'antd'
import { useState } from 'react'
import DeleteSensor from 'src/components/DeleteSensor'
import Button, { BackButton } from 'src/components/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import styled from 'styled-components'

const TitleFont = styled.span`
  font-weight: normal;
  color: #FF631B;
  font-size: 35px;
`

const TitleWrap = styled.div`
  display: flex;
  justify-content: center;
`

const MachineContainer = styled.div`
  display: flex;
  border-radius: 6px;
  box-shadow: 1px 1px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #e2e2e2;
  padding: 0.5em 1em;
  align-items: center;
  justify-content: space-between;
`

const MachineItem = styled.div`
  display: flex;
  align-items: center;
`

const UniFont = styled.span`
  font-weight: normal;
  font-size: ${props => props.small ? '14px' : '24px'};
`

const ConfiguredPluginModal = (props) => {
  const {
    sensor = {},
    setFormActive,
    setSelectedPort,
    selectedPort
  } = props

  return (
    <>
      <div>
        <BackButton onClick={() => {
          setSelectedPort()
          setFormActive(1)
        }}
        />
      </div>
      <TitleWrap>
        <TitleFont>{selectedPort}</TitleFont>
      </TitleWrap>
      <div>
        <span>Machines</span>
        {sensor.plugins && sensor.plugins.map(item => {
          const { name, coverImage, SensorPlugin } = item
          const { port } = SensorPlugin
          if (port === selectedPort) {
            return (
              <MachineContainer key={name}>
                <MachineItem>
                  <MachineItem>
                    <FontAwesomeIcon color='#7f9cf0' style={{ fontSize: '1.5em', margin: '0 0.5em' }} icon={['fas', coverImage]} />
                  </MachineItem>
                  <MachineItem>
                    <UniFont>{name}</UniFont>
                  </MachineItem>
                </MachineItem>
                <MachineItem>
                  <i style={{ fontSize: '1em', margin: '0 1em' }} className='far fa-check-circle' />
                  <UniFont small>Configured</UniFont>
                </MachineItem>
              </MachineContainer>
            )
          }
        })}
      </div>

    </>
  )
}

export default ConfiguredPluginModal
